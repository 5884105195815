import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import api from "../../services/api";
import { useSelector } from "react-redux";
import { Flex, isMobile, breakpoints, isMobileMediaQuery } from "../../helpers/styles";
import { openPrivacyNTerms } from "../../helpers";
import { generateUUID } from "../Functions";
import TextInput from "../Inputs/TextInput";
import Button from "../Button";
import ExpandableContainer from "../ExpandableContainer";
import ButtonLink from "../ButtonLink";
import logo from "./../../assets/images/logo-repetit.png";
import SearchIcon from "./../../assets/images/icon-search.png";
import WhatsappIcon from "./../../assets/images/whatsapp.svg";
import InstagramIcon from "./../../assets/images/instagram.svg";
import TiktokIcon from "./../../assets/images/tiktok.svg";
import FacebookIcon from "./../../assets/images/facebook.svg";
import EuRecicloLogo from "./../../assets/images/eu-reciclo.png";
import GoogleSelo from "./../../assets/images/google-selo.png";

function Footer({ mini, ...props }) {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.user);
    const [menuStatus, setMenuStatus] = useState({
        company: false,
        customer: false,
        discover: false,
        stayin: false,
    });
    const [values, setValues] = useState({ search: "" });
    const [sendingNewsletter, setSendingNewsletter] = useState(false);

    const scrollToAbout = (e) => {
        const sobre = document.body.querySelector("#sobre");

        if (window.location.pathname === "/") {
            e.preventDefault();
            sobre &&
                sobre.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
        }
    };

    const handleChange = (e) => {
        let {
            target: { name, value },
        } = e;
        let newValues = { ...values };

        if (name) {
            newValues[name] = value;

            setValues(newValues);
        }
    };

    const handleSubmitNewsletter = async () => {
        if (
            !values.newsletter_email ||
            values.newsletter_email.length === 0 ||
            !values.newsletter_name ||
            values.newsletter_name.length === 0
        ) {
            return;
        }

        setSendingNewsletter(true);

        let response = await api
            .post("/newsletter", {
                name: values.newsletter_name,
                email: values.newsletter_email,
            })
            .catch((err) => err.response);

        setSendingNewsletter(false);

        if (response.status === 201) {
            alert("Subscrição efetuada com sucesso!");
        } else {
            alert("Algo deu errado em sua subscrição, por favor tente novamente.");
        }
    };

    const setSearch = async (valueSearch) => {
        let userSessionServerData = window.userCurrentServerData(user);
        let event_id = generateUUID();
        let event_time = Math.round(Date.now() / 1000);

        window.fbq("track", "Search", { search_string: valueSearch }, { eventID: event_id });

        await api
            .post("/facebook_ads_event", {
                data: [
                    {
                        event_name: "Search",
                        event_id: event_id,
                        event_time: event_time,
                        event_source_url: window.location.href,
                        action_source: "website",
                        user_data: userSessionServerData,
                        custom_data: {
                            search_string: valueSearch,
                        },
                    },
                ],
            })
            .then((res) => console.log("EVENTO Search", res.data))
            .catch((res) => res.response);
        navigate(`/produtos?search=${encodeURIComponent(valueSearch)}`);
    };

    const handleSearch = async (e) => {
        e && e.preventDefault();

        if (!values.search || values.search.trim().length === 0) {
            return;
        }

        // eslint-disable-next-line
        dataLayer.push({
            event: "search",
            search_string: values.search,
        });

        navigate(`/produtos?search=${encodeURIComponent(values.search)}`);
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Container id="footer" mini={mini}>
            <div>
                {mini !== true && (
                    <>
                        {location.pathname !== '/' ? (
                            <Flex direction="row" padding="24px 0 16px 0" wrap="no-wrap">
                                <Logo width="154" height="52" src={logo} alt="Re Petit Logo" />
                                {!isMobile && (
                                    <FooterText title={1} margin="auto 0 auto 38px">
                                        circulando peças infantis
                                    </FooterText>
                                )}
                                <Flex
                                    margin="0 0 0 auto"
                                    as="form"
                                    minWidth={isMobile && "222px"}
                                    maxWidth={isMobile ? "45%" : "250px"}
                                    onSubmit={handleSearch}
                                >
                                    <TextInput
                                        width="250px"
                                        margin="0"
                                        placeholder="O que você busca?"
                                        outlined
                                        name="search"
                                        value={values.search}
                                        onChange={handleChange}
                                        append={
                                            <Button
                                                type="button"
                                                link
                                                margin="0"
                                                padding="0"
                                                onClick={() => setSearch(values.search)}
                                            >
                                                <img src={SearchIcon} width="24px" alt="Search Icon" />
                                            </Button>
                                        }
                                    />
                                </Flex>
                            </Flex>
                        ) : (
                            <Flex direction={!isMobile ? "row" : 'column'} padding="24px 0 16px 0" wrap="no-wrap" justifyContent="flex-start">
                                <Flex direction='column' alignItems='flex-start' margin='0px 24px 0px 0px' width={!isMobile && "685px"}>
                                    <Logo width="154" height="52" src={logo} alt="Re Petit Logo" />
                                    <FooterText title={1} margin="10px 0 0 0">
                                        circulando peças infantis
                                    </FooterText>
                                </Flex>

                                <Flex direction='column' justifyContent="flex-start" alignItems='flex-start'>
                                    <FooterText>
                                        <h2>Re Petit</h2>
                                        A Re Petit é um brechó online de compra e venda de artigos infantis seminovos. Contamos com uma equipe especializada em curadoria para oferecer produtos em excelente estado. Trabalhamos com roupas de bebês e crianças de 0 a 14 anos, acessórios, sapatos, brinquedos, objetos e utensílios das melhores marcas nacionais e internacionais.
                                        <br />
                                        A Re Petit nasceu em 2020 para ajudar as famílias a embarcarem no consumo consciente de uma forma prática e segura.
                                        <br />

                                        {isExpanded && (
                                            <FooterText>
                                                <br />
                                                <p>Benefícios de comprar roupas em um brechó infantil?</p>
                                                <ul>
                                                    <li>
                                                        <strong>Economia: </strong>Roupas de qualidade por preços mais baixos, ideal para o crescimento rápido das crianças.
                                                    </li>
                                                    <li>
                                                        <strong>Sustentabilidade: </strong>Reduz o consumo de roupas novas, diminui o descarte de têxteis e promove a economia circular.
                                                    </li>
                                                    <li>
                                                        <strong>Variedade: </strong> Encontra peças únicas e de marcas variadas, muitas vezes com melhor qualidade do que as fast fashion.
                                                    </li>
                                                    <li>
                                                        <strong>Consciência: </strong>Incentiva o consumo consciente e responsável, além de ajudar a cuidar do meio ambiente.
                                                    </li>
                                                    <li>
                                                        <strong>Originalidade: </strong>Permite montar looks personalizados e exclusivos para seus filhos.
                                                    </li>
                                                </ul>

                                                <br />

                                                <h2>Como funciona um brechó infantil?</h2>
                                                <p>Um brechó infantil funciona como uma loja de roupas de segunda mão, mas especializada em peças para bebês e crianças.</p>
                                                <ul>
                                                    <li>
                                                        <strong>Como recebemos: </strong>Pessoas levam ou enviam roupas infantis em bom estado para o brechó, que seleciona e expõe para venda online.
                                                    </li>
                                                    <li>
                                                        <strong>Seleção das peças: </strong>As peças são selecionadas e organizadas por tamanho e tipo de roupa.
                                                    </li>
                                                    <li>
                                                        <strong>Venda: </strong>As roupas são vendidas a preços mais baixos do que em lojas tradicionais, atraindo consumidores que buscam economia e qualidade. Suas peças podem ser vendidas em menos de 45 dias!
                                                    </li>
                                                    <li>
                                                        <strong>Rotação constante: </strong>O estoque do brechó está em constante renovação, com novas peças chegando e saindo frequentemente.
                                                    </li>
                                                </ul>

                                                <br />

                                                <h2>Como vender roupas usadas infantis online em um brechó?</h2>
                                                <p>Vender roupas infantis usadas online em um brechó é uma ótima forma de desapegar de peças que não servem mais em seus filhos, limpar seu guarda-roupas e usar os valores para outras finalidades.</p>
                                                <p>A <strong>Re Petit</strong> te ajuda com todo o processo de classificação e a <strong>vender roupas usadas de criança</strong>. Venda de forma segura e com toda a equipe para te auxiliar.</p>

                                                <br />

                                                <h2>O que é um brechó de roupas infantis?</h2>
                                                <p>Um <strong>brechó de roupas infantis</strong> é um lugar onde você pode comprar e vender roupinhas infantis usadas, mas em bom estado. É como uma loja de segunda mão, só que especializada em roupas para crianças.</p>

                                                <br />

                                                <h2>Roupas de bebê baratas em brechó Infantil</h2>
                                                <p>Encontrar roupinhas de bebê em bom estado e por um preço acessível é o sonho de muitos pais! Os brechós infantis são a solução perfeita para quem busca economizar sem abrir mão da qualidade.</p>
                                                <p>O <strong>desapego de roupas de bebê</strong> tráz economia, sustentabilidade e variedade de looks a um preço super reduzido. O <strong>bazar de roupas infantis</strong> é a forma mais fácil de encontrar roupas de luxo, grife ou importadas com preço baixo.</p>

                                                <br />

                                                <h2>Dicas para comprar roupinhas de bebê em brechó:</h2>
                                                <ul>
                                                    <li>
                                                        <strong>Verifique o estado de conservação: </strong>As roupas devem estar limpas, sem manchas ou furos.
                                                    </li>
                                                    <li>
                                                        <strong>Observe os detalhes: </strong>Verifique se os botões, zíperes e outros detalhes estão em bom estado.
                                                    </li>
                                                    <li>
                                                        <strong>Peça medidas: </strong>Se possível, peça as medidas das roupas para garantir que servirão no seu bebê.
                                                    </li>
                                                </ul>

                                                <br />

                                                <h2>Roupas em promoção no brechó online</h2>
                                                <p>Quem não ama uma boa promoção? No brechó online da Re Petit, você encontra peças incríveis com descontos ainda mais incríveis!</p>
                                                <p>Descontos em roupas infantis usadas, promoções e preços baixos são os diferenciais de um bazar de roupas infantis e você deve aproveitar. Peças selecionadas com carinho, enviadas com todo o cuidado que você merece.</p>

                                                <br />

                                                <h2>Onde comprar roupas infantis usadas?</h2>
                                                <p>A Re Petit é um brechó infantil online especializado em roupas, acessórios e outros itens para crianças de 0 a 14 anos. Eles possuem uma curadoria criteriosa das peças, garantindo que todas estejam em bom estado e com marcas de qualidade.</p>
                                                <p><strong>Como escolher um brechó infantil online confiável?</strong> A Re Petit é um dos maiores e mais completos sites de roupas infantis de segunda mão. O bazar infantil possui milhares de peças selecionadas para você economizar e receber no conforto de sua casa.</p>

                                                <br />

                                                <h2>Re Petit, brechó infantil em São Paulo</h2>
                                                <p> Recebemos roupas usadas infantis via correio, selecionamos e auxiliamos na venda das peças. Estamos localizados em São Paulo, mas atendemos todo o Brasil. Consulte nossa equipe especializada em roupas usadas para crianças.</p>
                                            </FooterText>
                                        )}
                                        <br />
                                        <ButtonLink onClick={toggleReadMore}>
                                            {isExpanded ? 'Leia menos' : 'Saiba mais'}
                                        </ButtonLink>
                                    </FooterText>
                                </Flex>
                            </Flex>
                        )}

                        <Separator />

                        <Flex
                            direction={isMobile ? "column" : "row"}
                            padding={!isMobile && "32px 0 26px 0"}
                            alignItems="flex-start"
                            justifyContent="space-between"
                        >
                            <ExpandableContainer
                                title="Empresa"
                                full
                                mobile
                                value={menuStatus.company}
                                onChange={(value) =>
                                    value !== undefined &&
                                    setMenuStatus({
                                        company: value,
                                    })
                                }
                            >
                                <Flex direction="column" alignItems="flex-start">
                                    <Flex desktop>
                                        <Title>Empresa</Title>
                                    </Flex>

                                    <Links vertical>
                                        <Button
                                            justifyContent="flex-start"
                                            link
                                            as="a"
                                            href="/#sobre"
                                            onClick={scrollToAbout}
                                            target="_top"
                                            padding="initial"
                                            margin="initial"
                                        >
                                            Sobre nós
                                        </Button>
                                        <a href="/produtos">Compre</a>
                                        <a href="/box">BOX</a>
                                        <a href="/desapegue">Desapegue</a>
                                        <a href="/novidades">Novidades</a>
                                        <a href="/blog">Blog</a>
                                        {/* <a href="/produtos">Guia de Estilo</a> */}
                                        <a href="/minha-conta">Minha conta</a>
                                    </Links>
                                </Flex>
                            </ExpandableContainer>

                            <ExpandableContainer
                                title="Dúvidas"
                                full
                                mobile
                                value={menuStatus.customer}
                                onChange={(value) =>
                                    value !== undefined &&
                                    setMenuStatus({
                                        customer: value,
                                    })
                                }
                            >
                                <Flex direction="column" alignItems="flex-start">
                                    <Flex desktop>
                                        <Title>Dúvidas</Title>
                                    </Flex>

                                    <Links vertical>
                                        <Link to="/faq">Perguntas frequentes</Link>
                                        <Link to="/faq/como-comprar">Como comprar</Link>
                                        <Link to="/faq/como-vender">Como vender</Link>
                                        <ButtonLink onClick={() => openPrivacyNTerms("privacy")}>
                                            Privacidade
                                        </ButtonLink>
                                        <ButtonLink onClick={() => openPrivacyNTerms("terms")}>
                                            Termos e condições
                                        </ButtonLink>
                                        <Link to="/faq#politica-de-troca">Políticas de Troca e Reembolso</Link>
                                        <ButtonLink onClick={() => openPrivacyNTerms("promotions")}>
                                            Regras Promoções E Cupons
                                        </ButtonLink>
                                        <Link to="/faq#formas-de-pagamento">Formas de pagamento</Link>
                                        <Link to="/faq#contato">Contato</Link>
                                    </Links>
                                </Flex>
                            </ExpandableContainer>

                            <ExpandableContainer
                                title="Fique por dentro"
                                full
                                mobile
                                value={menuStatus.stayin}
                                onChange={(value) =>
                                    value !== undefined &&
                                    setMenuStatus({
                                        stayin: value,
                                    })
                                }
                            >
                                <Flex direction="column" alignItems={isMobile ? "center" : "flex-start"}>
                                    <Flex desktop>
                                        <Title>Conecte-se</Title>
                                    </Flex>
                                    <Flex margin="0 0 20px 0" mobile>
                                        <FooterText title={1} textAlign={isMobile && "center"}>
                                            Conecte-se
                                        </FooterText>
                                    </Flex>
                                    <Links vertical={!isMobile} hideBars>
                                        <a href="https://api.whatsapp.com/send?phone=5511976922404" target="_blank">
                                            <img
                                                src={WhatsappIcon}
                                                height={isMobile ? "35px" : "auto"}
                                                alt="Whatsapp logo"
                                            />
                                            <Flex desktop>Whatsapp</Flex>
                                        </a>
                                        <a href="https://www.facebook.com/userepetit" target="_blank">
                                            <img
                                                src={FacebookIcon}
                                                height={isMobile ? "35px" : "auto"}
                                                alt="Facebook logo"
                                            />
                                            <Flex desktop>Facebook</Flex>
                                        </a>
                                        <a href="http://instagram.com/re_petit" target="_blank">
                                            <img
                                                src={InstagramIcon}
                                                height={isMobile ? "35px" : "auto"}
                                                alt="Instagram logo"
                                            />
                                            <Flex desktop>Instagram</Flex>
                                        </a>
                                        <a href="https://www.tiktok.com/@repetit_?_t=8hY5s2R9b5K&_r=1" target="_blank">
                                            <img
                                                src={TiktokIcon}
                                                width={"17px"}
                                                height={isMobile ? "35px" : "auto"}
                                                alt="TikTok logo"
                                            />
                                            <Flex desktop>TikTok</Flex>
                                        </a>
                                    </Links>

                                    <Flex margin="8px 0 0px 0" desktop>
                                        <Title>Certificados</Title>
                                    </Flex>
                                    <Flex margin="24px 0 8px 0" mobile>
                                        <FooterText title={1} textAlign={isMobile && "center"}>
                                            Certificados
                                        </FooterText>
                                    </Flex>

                                    <Flex direction="column">
                                        <a
                                            href="https://www.eureciclo.com.br/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <SealLogo src={EuRecicloLogo} height="68px" />
                                        </a>

                                        <a
                                            href="https://transparencyreport.google.com/safe-browsing/search?url=repetit.com.br&hl=pt_BR"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <SealLogo src={GoogleSelo} height="52px" />
                                        </a>
                                    </Flex>

                                    <Flex direction="column" width="100%" padding="0" margin="0 0 23px 0" mobile>
                                        <Flex width="100%" margin="25px 0">
                                            <Separator />
                                        </Flex>
                                        <FooterText title={1} textAlign={isMobile && "center"}>
                                            {isMobile ? "Assine nossa newsletter" : "newsletter"}
                                        </FooterText>
                                        <Flex direction="column" width="100%" padding="20px 0">
                                            <TextInput
                                                type="text"
                                                placeholder="Nome"
                                                flex="1"
                                                width="100%"
                                                margin="0 0 10px 0"
                                                outlined={1}
                                                name="newsletter_name"
                                                value={values.newsletter_name}
                                                onChange={handleChange}
                                                disabled={sendingNewsletter}
                                            />
                                            <TextInput
                                                type="text"
                                                placeholder="Email"
                                                flex="1"
                                                width="100%"
                                                margin="0 0 10px 0"
                                                outlined={1}
                                                name="newsletter_email"
                                                value={values.newsletter_email}
                                                onChange={handleChange}
                                                disabled={sendingNewsletter}
                                            />
                                            <Button
                                                width="100%"
                                                dark
                                                filled
                                                margin="0"
                                                onClick={handleSubmitNewsletter}
                                                disabled={
                                                    !values.newsletter_email ||
                                                    !values.newsletter_name ||
                                                    sendingNewsletter
                                                }
                                                loading={sendingNewsletter}
                                            >
                                                Assinar
                                            </Button>
                                        </Flex>
                                        <FooterText textAlign="center">
                                            Ao clicar em “assinar” você concorda em receber e-mails da Re Petit e aceita
                                            a{" "}
                                            <ButtonLink onClick={() => openPrivacyNTerms("privacy")}>
                                                Política de Privacidade
                                            </ButtonLink>{" "}
                                            e{" "}
                                            <ButtonLink onClick={() => openPrivacyNTerms("terms")}>
                                                Termos e Uso
                                            </ButtonLink>
                                            .
                                        </FooterText>
                                    </Flex>
                                </Flex>
                            </ExpandableContainer>

                            <Flex direction="column" maxWidth="591px" alignItems="flex-start" desktop>
                                <Title>newsletter</Title>
                                <FooterText>Receba nossas novidades e promoções!</FooterText>
                                <Flex direction="row" width="100%" padding="0" margin="14px 0 23px 0">
                                    <TextInput
                                        type="text"
                                        placeholder="Nome"
                                        flex="1"
                                        width="173px"
                                        outlined
                                        name="newsletter_name"
                                        value={values.newsletter_name}
                                        onChange={handleChange}
                                        disabled={sendingNewsletter}
                                    />
                                    <TextInput
                                        type="email"
                                        placeholder="Email"
                                        flex="1"
                                        width="270px"
                                        margin="0 0 0 10px"
                                        outlined
                                        name="newsletter_email"
                                        value={values.newsletter_email}
                                        onChange={handleChange}
                                        disabled={sendingNewsletter}
                                    />
                                    <Button
                                        dark
                                        filled
                                        borderradius
                                        margin="0 0 0 10px"
                                        onClick={handleSubmitNewsletter}
                                        disabled={
                                            !values.newsletter_email || !values.newsletter_name || sendingNewsletter
                                        }
                                        loading={sendingNewsletter}
                                    >
                                        Assinar
                                    </Button>
                                </Flex>
                                <FooterText>
                                    Ao clicar em “assinar” você concorda em receber e-mails da Re Petit e aceita a{" "}
                                    <ButtonLink onClick={() => openPrivacyNTerms("privacy")}>
                                        Política de Privacidade
                                    </ButtonLink>{" "}
                                    e <ButtonLink onClick={() => openPrivacyNTerms("terms")}>Termos e Uso</ButtonLink>.
                                </FooterText>
                            </Flex>
                        </Flex>

                        <Flex desktop>
                            <Separator />
                        </Flex>
                    </>
                )}

                <Bottom justifyContent="space-between" width="100%" padding="26px 0" desktop>
                    <p>
                        Rua Andréa Paulinetti, 53 - CEP 04707-050, Brooklin, São Paulo/SP
                        <br />
                        RE PETIT SERVICOS DE INTERMEDIACAO DE NEGOCIOS LTDA • CNJP 35.445.372/0001-06
                        <br />
                        <small>2022 REPETIT® TODOS OS DIREITOS RESERVADOS</small>
                    </p>
                    <Links>
                        {mini ? (
                            <>
                                <ButtonLink onClick={() => openPrivacyNTerms("terms")}>Termos de Uso</ButtonLink>
                                <ButtonLink onClick={() => openPrivacyNTerms("privacy")}>
                                    Política de Privacidade
                                </ButtonLink>
                            </>
                        ) : (
                            <a href="/#sobre">Sobre</a>
                        )}
                    </Links>
                </Bottom>
            </div>

            <Bottom
                width="calc(100% - 40px)"
                padding="22px 20px"
                background="#CACAD0"
                justifyContent="center"
                dark
                mobile
            >
                <p>
                    Rua Andréa Paulinetti, 53 - CEP 04707-050, Brooklin, São Paulo/SP
                    <br />
                    RE PETIT SERVICOS DE INTERMEDIACAO DE NEGOCIOS LTDA • CNJP 35.445.372/0001-06
                    <br />
                    <small>2022 REPETIT® TODOS OS DIREITOS RESERVADOS</small>
                </p>
            </Bottom>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    background-color: #f7f9f9;

    @media screen and (max-width: ${breakpoints.mobile}px) {
        & > div:first-of-type {
            width: calc(100vw - 40px);
        }
    }

    & > div:first-of-type {
        height: 100%;
        margin: 0 auto;
        max-width: 1222px;
    }
`;

const Logo = styled.img`
    ${isMobileMediaQuery} {
        max-width: 45%;
        object-fit: contain;
    }
`;

const Title = styled.span`
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    line-height: 3;
    letter-spacing: 2.2px;
    color: #e4858c;
    margin-top: 0px;
    margin-bottom: 8px;
`;

const FooterText = styled.span`
    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
    color: #676777;

    a {
        color: #676777;
    }

    ${(props) =>
        props.title &&
        `
        font-family: 'SackersGothicStd';
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 900;
        letter-spacing: 2.2px;
        margin-bottom: 6px;
    `}

    ${({ margin }) => margin && `margin: ${margin};`}
`;

const Separator = styled.div`
    background-color: #cacad1;
    width: 100%;
    height: 1px;
`;

const Bottom = styled(Flex)`
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    box-sizing: border-box;
    width: 100%;
    margin: 0;

    & > p {
        font-family: "SackersGothicStd";
        font-size: 11px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: 2.2px;
        text-align: left;
        color: ${(props) => (props.dark ? "#fff" : "#676777")};
        margin: 0;

        ${isMobileMediaQuery} {
            font-size: 12px;
            line-height: 1.67;
            letter-spacing: 0.48px;
            text-align: center;
        }
    }
`;

const Links = styled.div`
    display: flex;

    & > a {
        display: flex;
        align-items: center;
        font-family: "Graphik";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-decoration: underline;
        text-transform: capitalize;
        color: #676777;

        :hover {
            text-decoration: underline;
        }

        :not(:last-child) {
            ${({ vertical }) =>
        vertical
            ? `
                    margin-bottom: 4px;
                `
            : `
                    padding-right: 15px;                    
                    margin-right: 15px;
            `};
            ${({ hideBars, vertical }) =>
        !hideBars &&
        !vertical &&
        `
                    border-right: 1px solid #cacad1;
            `};
        }

        img {
            margin-right: 8px;
        }
    }

    ${({ vertical }) =>
        vertical &&
        css`
            flex-direction: column;

            & > a {
                text-decoration: none;
            }
        `};
`;

const SealLogo = styled.img`
    :not(:last-of-type) {
        margin-bottom: 16px;
    }
`;

export default Footer;
